.status-tag.ant-tag {
  font-weight: 500;
  border: solid 1px;
}
.issue-detail-layout {
  height: calc(100vh - 260px);
  overflow: auto;
  @media screen and (max-width: 999px) {
    height: calc(100vh - 236px);
  }
}
.fixed-height {
  @media screen and (max-width: 999px) {
    padding: 0 !important;
  }
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.instruction-tab-details-summary {
  background-color: @grey-2;
  .ant-card-body {
    padding: 16px;
  }
  .view-details {
    padding: 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    height: auto;
    margin-top: 8px;
  }
  .ant-divider-horizontal {
    margin: 24px -16px;
    width: unset;
  }
}
.deactivated-color {
  background: @light-blue;
}
.empty-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.empty-text-secondary {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: @textColor-secondary;
  margin-top: 8px;
}
.clear-filter-button {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #096dd9;
  &:focus,
  &:hover {
    color: #096dd9;
  }
  @media screen and (max-width: 999px) {
    padding: 0;
  }
}
.issue-heading {
  max-width: 50%;
}
.response-card {
  .ant-card-body {
    padding: 16px;
  }
  background-color: #f5f5f5;
  width: 448px;
  .ant-form-item:not(:first-child) {
    margin-bottom: 0px;
  }
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload.ant-upload-select {
  width: 72px;
  height: 72px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  transition: border-color 0.3s;
  .add-button {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ant-upload-list-picture-card-container {
  width: 72px;
  height: 72px;
}
.ant-badge-status-dot {
  width: 4px;
  height: 4px;
}
.recommendation-collapse {
  background: transparent;
  .ant-collapse-header {
    width: fit-content;
  }
  .ant-collapse-item {
    border: none;
  }
  & > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    margin-bottom: 5px;
  }
  .panel-header {
    font-weight: 500;
    width: fit-content;
    color: @label-color;
    padding: 8px 12px 8px 12px;
    border-radius: 40px;
    border: 1px solid;
    background: @background-color;
  }
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .previous-details-card-wrapper {
    padding: 16px;
    border: 1px solid @border-color;
    border-radius: 16px;
  }
}
.ant-modal-footer {
  border-top: none;
  padding: 0 16px 16px;
}
@media screen and (max-width: 999px) {
  .add-response-modal.ant-modal {
    max-width: 100%;
    bottom: 0;
    margin: 0;
    vertical-align: bottom;
    padding: 0;
    .ant-modal-content {
      border-end-end-radius: 0;
      border-end-start-radius: 0;
      max-height: 90vh;
      height: 90vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: auto;
    }
  }
}
.success-container {
  background-image: url(/assets/images/cover.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
  flex: 1 1;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  &-content {
    padding: 32px 32px;
    font-size: 14px;
    position: relative;
    z-index: 5;
    h1 {
      font-size: 32px;
      margin-bottom: 80px;
    }
    svg {
      margin-bottom: 80px;
    }
  }
}

.success-container::after {
  content: '';
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: rgba(242, 244, 247, 0.9);
}

.issue-activity {
  background-color: @white-color;
  .response-detail {
    &:not(:first-child) {
      margin-top: 24px;
    }
    .name-and-time {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: @grey-10;
      .ellipsis-text {
        max-width: 200px;
      }
    }
    .ant-card-body {
      padding: 16px;
    }
    .ant-card-bordered {
      max-width: 384px;
      width: fit-content;
      @media screen and (max-width: 999px) {
        max-width: 310px;
      }
    }
    .grey-background {
      background-color: #f5f5f5;
    }
  }
}
.issue-report-button {
  background: #202a3f;
  color: @white-color;
  font-size: 16px;
  font-weight: 500;
  &.ant-btn:hover,
  &.ant-btn:focus,
  &.ant-btn:active {
    background: #202a3f;
    color: #ffffff;
    border: 1px solid #202a3f;
  }
  svg {
    fill: @white-color;
  }
  &.ant-btn[disabled] {
    svg {
      fill: rgba(0, 0, 0, 0.25);
    }
    &:hover svg {
      fill: rgba(0, 0, 0, 0.25);
    }
  }
}
.add-response-button {
  position: sticky;
  bottom: 0;
  left: 0;
  margin: 0 -16px;
  padding: 12px 16px 0;
  button {
    height: 46px;
  }
}
.ant-descriptions
  .ant-descriptions-item-container
  .ant-descriptions-item-label {
  color: @grey-8;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
.ant-descriptions
  .ant-descriptions-item-container
  .ant-descriptions-item-content {
  color: @grey-9;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}
.ant-descriptions .ant-descriptions-row > td {
  padding-bottom: 5px;
}
.ant-descriptions .ant-descriptions-row > th {
  padding: 0;
}
#view-details-drawer,
#add-response-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .ant-drawer-body {
    padding: 16px;
  }
  .ant-drawer-content {
    border-radius: 16px 16px 0px 0px;
  }
  .ant-drawer-header-title {
    flex-direction: row-reverse;
  }
  .ant-drawer-header {
    padding: 16px;
    .ant-drawer-title {
      font-weight: 500;
      font-size: 20px;
      line-height: 28px;
      color: #202a3f;
    }
    .ant-drawer-close {
      margin: 0;
    }
  }
}
@media screen and (max-width: 999px) {
  .ant-card-bordered {
    border: 1px solid @border-color;
  }
  .ant-card-body {
    padding: 16px;
  }
  .ant-card-bordered:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .filters-search .filter-div {
    margin: 0;
  }
  .ant-btn-round.ant-btn-icon-only {
    padding: 5px;
  }
  .mobile-card-wrapper {
    .issue-detail-card {
      > .ant-card-body {
        padding: 0 12px;
      }
    }
    .ant-card-body {
      padding: 0;
      width: 100%;
    }
    .instruction-tab-details-summary {
      &.ant-card {
        border-radius: 0;
        border-width: 1px 0;
      }
      margin-bottom: 24px;
    }
    .issue-activity-mobile {
      height: auto;
      flex: 1;
      overflow: visible;
      &.raised-issues {
        display: flex;
        align-items: flex-end;
      }
    }
  }
  .map-input {
    width: 230px;
  }
  .search-component {
    svg {
      fill: @label-color;
    }
  }
}
