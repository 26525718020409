#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;
  & > *:not(:last-child) {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}
.mobile-header {
  padding: 16px;
  background: white;
  height: 72px;
  .logo {
    padding: 0;
    height: unset;
  }
  .ant-btn {
    background: transparent;
    box-shadow: none;
  }
  .header-notification {
    & > * {
      margin-right: 10px;
    }
    .profileImage {
      margin: 0;
    }
  }
}
#nav-bar-dropdown {
  .ant-dropdown {
    top: 72px !important; //to override inline style
    width: 100%;
    box-shadow: 0px 8px 11px 2px #ccc;
  }
  .ant-dropdown-menu {
    border-radius: 0;
    box-shadow:
      0px 3px 3px -4px rgb(0 0 0 / 12%),
      0 6px 6px 0 rgb(0 0 0 / 8%),
      0 9px 9px 8px rgb(0 0 0 / 5%);
    position: absolute;
    width: 100%;
    right: calc(-100% + 33px + 16px);
    padding: 0;
    .ant-dropdown-menu-item {
      padding: 10px 32px;
      font-size: 16px;
    }
  }
}
.small-btn {
  height: 24px;
  padding: 2px 8px;
  span {
    font-size: 12px;
  }
}
@media screen and (max-width: 999px) {
  .page-header {
    padding: 16px;
    font-size: 20px;
    font-weight: 500;
  }
  .common-card-wrapper {
    background: white;
    padding: 16px;
  }
}
@media screen and (max-width: 575px) {
  .ant-layout-header {
    height: unset;
  }
}
.gx-sidebar-content {
  & > .ant-menu {
    & > .ant-menu-item {
      margin: 12px 0;

      & > .menu-icons {
        font-size: 20px;
      }

      & > span {
        font-size: 18px;
      }
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
      border: none;
    }
  }
}
.ant-table-tbody > tr > td {
  max-width: 500px;
}
tr.ant-table-placeholder > td.ant-table-cell {
  border-bottom: none;
}
.ant-table tr:last-of-type {
  td {
    border-bottom: none;
  }
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  transition: none;
}
.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px rgba(252, 80, 39, 0.2);
}
.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}
.cursor-not-allowed {
  cursor: not-allowed;
}
.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: #efefef;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .kebab-icon.ant-btn {
    padding: 6px;
    background: #f2f4f7;
    height: 32px;
  }
  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

//CUSTOM STYLE CLASSES START

//margin & padding style
.m-0 {
  margin: 0;
}
.mt-5 {
  margin-top: 5px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-50 {
  margin-top: 50px;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-5 {
  margin-bottom: 5px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-16 {
  margin-bottom: 16px;
}
.ml-5 {
  margin-left: 5px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-12 {
  margin-left: 12px;
}
.ml-15 {
  margin-left: 15px;
}
.ml-16 {
  margin-left: 16px;
}
.mb-0 {
  margin-bottom: 0px;
}

.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mr-5 {
  margin-right: 5px;
}
.mr-8 {
  margin-right: 8px;
}
.mr-10 {
  margin-right: 10px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-20 {
  margin-right: 20px;
}

.p-0 {
  padding: 0;
}
.p-5 {
  padding: 5px;
}
.p-10 {
  padding: 10px;
}
.p-14 {
  padding: 14px;
}
.p-16 {
  padding: 16px;
}
.pb-10 {
  padding-bottom: 10px;
}
//margin & padding style

// flex-box related style
.d-flex {
  display: flex;
}
.d-flex-wrap {
  flex-wrap: wrap;
}
.d-flex-nowrap {
  flex-wrap: nowrap;
}
.d-none {
  display: none;
}
.d-inline-flex {
  display: inline-flex;
}
.d-grid {
  display: grid;
}

.flex-horizontal {
  flex-direction: row;
}

.flex-vertical {
  flex-direction: column;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-shrink-0 {
  flex-shrink: 0;
}

.flex-shrink-1 {
  flex-shrink: 1;
}

.flex-shrink-2 {
  flex-shrink: 2;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-self-end {
  align-self: flex-end;
}
.font-size-16 {
  font-size: 16px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-10 {
  font-size: 10px;
}
.font-size-22 {
  font-size: 22px;
}
// flex-box related style end
//height classes
.height-auto {
  height: auto;
}

.fill-height {
  height: 100%;
}
//width classes end
//width classes
.height-auto {
  height: auto;
}
.width-auto {
  width: auto;
}
.width-percent-20 {
  width: 20%;
}

.width-600 {
  width: 600px;
}

.width-400 {
  width: 400px;
}
.width-300 {
  width: 300px;
}
.width-200 {
  width: 200px;
}

.max-width-900 {
  max-width: 900px;
}

.width-percent-50 {
  width: 50%;
}

.width-percent-60 {
  width: 60%;
}

.width-percent-40 {
  width: 40%;
}

.width-percent-5 {
  width: 5%;
}
.height-percent-100 {
  height: 100%;
}
.width-percent-100 {
  width: 100%;
}

.width-percent-25 {
  width: 25%;
}

.fill-width {
  width: 100%;
}
//width classes

//cursor
.pointer {
  cursor: pointer;
}
.cursor-disabled {
  cursor: not-allowed;
}
.pointerEvents-none {
  pointer-events: none;
}
//cursor

//border style
.b-0 {
  border: none;
}
//border style
.stage-config {
  .notification-row {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
  .disabled-background {
    background-color: @backgroundColor;
  }
  .config-div {
    padding: 0 5px;
  }
  .note-text {
    margin: -6px 0 10px 0;
  }
  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }
  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #b3b6c3;
  }
}
.form-buttons {
  display: flex;
  justify-content: flex-end;
  & button:not(:last-of-type) {
    margin-right: 10px;
  }

  .ant-btn-default:not(:disabled).cancel-button {
    background: @light-blue;
    border-radius: 40px;
    color: @grey-10;
    border-color: @light-blue;
  }

  .save-button {
    border: none;
  }
}
.change-password-content {
  display: flex;
  justify-content: center;
}
.set-password-form {
  .ant-form-item-with-help {
    margin-bottom: 20px;
  }
  .password-input {
    .ant-form-item-explain,
    .ant-form-item-explain-connected {
      display: none;
    }
  }
}
.password-text {
  width: 100%;
  margin: 0px 0px 25px 0px;
  .ant-checkbox-group {
    display: inline-block;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @active-tag-color;
  }
  .ant-checkbox-disabled + span {
    color: @colorLabel;
  }
  .ant-checkbox-wrapper + .ant-checkbox-wrapper {
    display: flex;
    margin: 0px;
  }
}
.change-password-inside-content {
  max-width: 600px;
  width: 100%;
  margin: 16px;
  padding: 20px 20px 20px;
  background-color: @whiteColor;
  .border-radius(@border-radius-lg);
  .box-shadow(@gx-card-shadow);
  font-size: 14px;
  position: relative;
  z-index: 5;
  .ant-form-item:nth-child(2) {
    margin-bottom: 10px;
  }
}

.ant-tag {
  border-radius: 10px;
  border: none;
}
.light-tag {
  background: @inactive-tag-background;
}
.inactive-tag {
  background: @inactive-tag-background;
  color: @colorLabel;
}
.draft-tag {
  background: @draft-background;
  color: @whiteColor;
}

.active-tag {
  background: @active-tag-color;
  color: @active-tag-text-color;
}

.yellow-tag {
  background: @yellow-tag-color;
  color: @yellow-tag-text-color;
}

.red-tag {
  background: @red-tag-color;
  color: @red-tag-text-color;
}

.search-component {
  border-radius: 40px;
  padding: 4px 8px;
  height: 100%;
  input {
    line-height: normal;
  }
}

.table {
  .ant-select:not(.ant-select-customize-input)
    .ant-select-selector
    .ant-select-selection-search-input {
    background: @whiteColor;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 6px;
    border: 1px solid @border-color;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: auto;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: 1px solid @border-color;
  }
}

.ant-table .ant-table-tbody .ant-btn {
  height: 32px;
  margin-right: 20px;
}

.header {
  h1 {
    margin-bottom: 0px;
  }
}

.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
  background: @headerTextColor;
}
.ant-switch:hover:not(.ant-switch-disabled) {
  background: rgba(0, 0, 0, 0.25);
}
.ant-switch:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}
.userprofile-divider {
  margin: 5px 0;
  border: 1px dashed;
}

.sample-file {
  color: @dq-colorPrimary;
}
.text-primary {
  color: @dq-colorPrimary;
}
.import-divider {
  margin: 10px 0;
  border-color: @black-color;
}

.import-modal {
  // To overwrite antd by default
  width: 570px !important;
  .import-required {
    .ant-row.ant-form-item {
      margin-bottom: 0px;
    }
  }
  ul {
    margin-bottom: 0px;
  }
  .import-additional-info {
    h4 {
      min-width: fit-content;
    }
  }
  .import-error {
    h4 {
      color: red;
    }
    .ant-btn {
      background: @inactive-tag-background;
    }
  }
  .note-error {
    color: red;
  }
}
.text-no-break {
  word-break: keep-all;
}
.text-break {
  word-break: break-word;
}
.ant-btn span {
  line-height: normal;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  color: @colorLabel;
  font-weight: 500;
}
.ant-table-wrapper .ant-table-tbody > tr.ant-table-row:hover > td {
  background: @light-blue;
  .ant-btn {
    svg {
      fill: white;
    }
  }
}
.checklist-row {
  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: #fed3c9;
    .ant-btn {
      svg {
        fill: white;
      }
    }
    .kebab-icon.ant-btn {
      background: @whiteColor;
    }
  }
  .checklist-approvers {
    fill: none;
    path {
      fill: @primary-color;
    }
  }
  .ant-divider-horizontal {
    margin: 0;
  }
}
.row-dark {
  background: @light-blue;
  .action-icons {
    svg {
      fill: white;
    }
  }
}
.row-orange {
  background: #fed3c9;
  .action-icons {
    .kebab-icon {
      padding: 6px;
      background: @whiteColor;
    }
    .checklist-approvers {
      fill: none;
      path {
        fill: @primary-color;
      }
    }
    svg {
      fill: white;
    }
  }
}
.row-disabled {
  background: #f5f5f5;
  .action-icons {
    .info-icon,
    svg {
      path {
        fill: #fff;
      }
    }
    .kebab-icon {
      padding: 6px;
      background: white;
      svg {
        path {
          fill: #202a3f;
        }
      }
    }
    .checklist-approvers {
      fill: none;
      path {
        fill: @primary-color;
      }
    }
    svg {
      fill: #e8e8e8;
      path {
        fill: #d9d9d9;
      }
    }
  }
}
.tenant-select-row {
  background: @layout-header-background;
  padding: @layoutHeaderPadding;
  min-height: 56px;
  .filter-wrapper {
    display: flex;
    align-items: center;
    border: 1px solid @inactive-tag-background;
    border-radius: 40px;
    padding-left: 8px;
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      box-shadow: none;
      border: none;
      padding: 0;
      background: transparent;
      .ant-select-selection-item {
        line-height: 34px;
      }
    }
  }
}
.required-mark {
  color: @dangerColor;
  font-family: 'Simsun';
  margin-right: 4px;
}
.text-secondary {
  color: @colorLabel;
}
.text-color {
  color: @primary-color;
}
.text-danger {
  color: @dangerColor;
}
.text-success {
  color: @success-color;
}
.text-warning {
  color: rgb(255, 193, 7);
}
.white-space-pre-line {
  white-space: pre-line;
}
.fw-normal {
  font-weight: @font-weight-normal;
}
.fw-medium {
  font-weight: @font-weight-medium;
}
.fw-semi-bold {
  font-weight: @font-weight-semi-bold;
}
.text-underline {
  text-decoration: underline;
}
.filter-div {
  .ant-select-selection-item {
    line-height: normal;
  }
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.bottom-0 {
  bottom: 0;
}
.top-0 {
  top: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}
.overflow-auto {
  overflow: auto;
}
.overflow-y-hidden {
  overflow-y: hidden;
}
.opacity-0 {
  opacity: 0;
}
.infinite-loader {
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background: rgba(255, 255, 255, 0.8);
}
.infinite-data-container {
  top: 0;
  .ant-empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}
.mobile-card-wrapper:not(:last-of-type) {
  margin-bottom: 20px;
}
@media screen and (min-width: 1000px) {
  .ant-card-bordered:not(
      .comment-card,
      .version-log-card,
      .approvers-card,
      .notification-card,
      .plan-vs-actual-card,
      .instruction-tab-details-summary,
      .activity-card,
      .response-card,
      .response-detail-card
    ):not(.bordered) {
    border: none;
  }
}
@media screen and (max-width: 999px) {
  .closing-details {
    order: 2;
  }
  .mobile-card {
    border: 1px solid @border-color;
    padding: 16px;
    border-radius: 16px;
  }
  .mobile-card:not(:last-of-type) {
    margin-bottom: 25px;
  }
  .ant-card-bordered {
    border: 1px solid @grey-4;
  }
  .ant-card .ant-card-body {
    padding: 16px;
  }
  .ant-card-bordered:not(:last-of-type) {
    margin-bottom: 16px;
  }
  .ant-btn {
    border: none;
  }
  .mobile-card-wrapper {
    padding: 0 16px 16px;
    &:not(:last-of-type) {
      padding: 0 16px 0px;
    }
  }
  .map-input {
    width: 230px;
  }
}
.ant-form-item-has-error .ant-input-number.ant-input-number-disabled,
.ant-form-item-has-error .ant-picker.ant-input-number-disabled {
  background-color: @grey-3;
  &:hover {
    background-color: @grey-3;
  }
}
.common-scroll-wrapper {
  overflow-y: auto;
  height: calc(100vh - 276px);
  min-height: 400px;
}
.ant-table-content {
  overflow-y: auto;
}
.ant-btn-icon-only {
  box-shadow: none;
}
.ant-form-item .ant-form-item-label > label {
  width: 100%;
  color: @colorLabel;
  white-space: nowrap;
}
.image-preview-wrapper {
  @media screen and (min-width: 999px) and (max-width: 1350px) {
    display: flex;
    flex-direction: column;
  }
  &.issue-images {
    flex-direction: row;
    .extra-count {
      margin: 0 0 0 5px;
    }
  }
  .ant-image-img {
    border-radius: 4px;
  }
  & div:not(:first-child) {
    &.ant-space-item {
      margin-right: 0 !important; //to override inline style of antd
    }
    .ant-image {
      display: none;
    }
  }
  gap: 0px !important; //to override inline style of antd
}
.card-header:not(.no-actions) {
  padding-right: 35px;
}
.zoom-controls {
  button.ant-btn {
    border-radius: 50%;
    background-color: #f2f4f7;
    padding-top: 8px;
  }
}
.whitespace-wrap {
  white-space: normal;
}
.grace-bar {
  align-items: center;
  display: flex;
  padding: 10px;
  background-color: crimson;
  justify-content: center;
}
.onsite-bar {
  align-items: center;
  display: flex;
  padding: 10px;
  background-color: @primary-color;
  justify-content: center;
}
.grace-bar-text {
  align-self: center;
  color: white;
  text-align: center;
}
.ant-btn[disabled].comment-button {
  color: white;
  background: rgba(166, 170, 178);
  border: none;
  &:hover {
    color: white;
    background: rgba(166, 170, 178);
    border: none;
  }
  border: none;
}
.has-select-all {
  .ant-select-selector {
    max-height: 200px;
    overflow: auto;
  }
}
.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 24px 0 15px;
}
.ant-tabs-nav {
  .ant-tabs-tab:not(:last-of-type) {
    margin: 0 4px 0 0;
  }
}
.ant-modal-content {
  .ant-modal-title {
    color: @headerTextColor;
  }
  .ant-modal-body {
    color: @grey-10;
  }
}
.recommendation-collapse {
  background: transparent;
  .ant-collapse-header {
    width: fit-content;
  }
  .ant-collapse-item {
    border: none;
  }
  & > .ant-collapse-item > .ant-collapse-header {
    padding: 0;
    margin-bottom: 5px;
  }
  .panel-header {
    font-weight: 500;
    width: fit-content;
    color: @colorLabel;
    padding: 8px 16px 8px 16px;
    border-radius: 40px;
    background: @backgroundColor;
  }
  & .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .previous-details-card-wrapper {
    padding: 16px;
    border: 1px solid @border-color;
    border-radius: 16px;
  }
}
.audio-player-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (min-width: 999px) and (max-width: 1350px) {
    display: flex;
    flex-direction: column;
  }
}
.extra-count {
  margin-left: 5px;
  background: @grey-4;
  border-radius: 6px;
  padding: 6px;
  font-weight: 400;
  @media screen and (min-width: 999px) and (max-width: 1350px) {
    margin: 5px 0px 0px 0px;
  }
}
.audio-player {
  height: 30px;
  width: 100%;
}
.expand-text.ant-typography {
  margin-bottom: 0;
}
.ant-typography,
.ant-typography-ellipsis,
.expand-text {
  white-space: pre-wrap;
  word-break: break-word;
}
.wrapper-404 {
  min-height: 100%;
  min-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .error-img {
    margin-bottom: 30px;
  }
  .error-msg {
    font-weight: 400;
    font-size: 24px;
    margin-bottom: 6px;
  }
  .home-link {
    font-size: 24px;
  }
}
.wrapper-network-offline {
  position: absolute;
  z-index: 500;
  height: calc(100vh - 72px);
  width: 100%;
  background-color: @backgroundColor;
  .offline-card {
    height: calc(100% - 40px);
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .offline-msg {
    font-weight: 400;
    font-size: 24px;
    margin-top: 30px;
  }
}
@media screen and (max-width: 999px) {
  .wrapper-404 {
    min-height: calc(100vh - 50px - 72px - 40px);
    min-width: auto;
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .error-msg {
      font-weight: 400;
      font-size: 20px;
      margin-bottom: 6px;
    }
    .home-link {
      font-size: 20px;
    }
  }
  .wrapper-network-offline {
    .offline-msg {
      font-size: 20px;
    }
  }
}

button.ant-btn.ant-btn-primary:focus,
button.ant-btn.ant-btn-primary:hover,
a.ant-btn.ant-btn-link:focus,
a.ant-btn.ant-btn-link:hover {
  background: #ff784f;
  border-color: #ff784f;
}
button.ant-btn-default:not(:disabled).cancel-button:focus,
button.ant-btn-default:not(:disabled).cancel-button:hover,
button.ant-btn.previous-comment:focus,
button.ant-btn.previous-comment:hover {
  background: #e3e3e3;
  border-color: #e3e3e3;
}
a:focus {
  text-decoration: underline;
}
.ant-menu,
.ant-dropdown-menu {
  a,
  a:focus {
    text-decoration: none;
    background-color: transparent;
  }
}
button.ant-btn-icon-only:focus {
  box-shadow: 0 0 3px 1px transparent;
}
.filter-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
  border: none;
  padding: 0;
  background-color: @textColor;
  height: 30px;
  min-width: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: @textColor;
  }
}

.collapsible-filter-wrapper {
  .ant-collapse,
  .ant-collapse-item,
  .ant-collapse-content {
    border: none;
  }
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    display: none;
  }
  .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }
  .ant-col:nth-of-type(2n) {
    padding: 0;
  }
  .ant-col:nth-of-type(2n + 1) {
    padding-left: 0;
  }
}
.common-dropdown-wrapper {
  .ant-select.ant-select-single .ant-select-selector {
    border-radius: 40px;
  }
  .ant-spin-blur {
    opacity: 1;
  }
  .ant-select-selection-item-remove .anticon {
    font-size: unset;
  }
  .tags-dropdown {
    .ant-select-selector {
      border-radius: 40px;
    }
  }
  .blinking-dropdown-border {
    &.ant-select.ant-select-single .ant-select-selector {
      border: 2px solid @primary-color;
      animation-name: blinking;
      animation-duration: 1s;
      animation-iteration-count: 5;
    }
  }
  @keyframes blinking {
    50% {
      border-color: @white-color;
    }
  }
}
.ant-input,
.ant-input-affix-wrapper,
.common-dropdown-wrapper .ant-select .ant-select-selector {
  border-color: @border-color;
}
#google-maps-container > .pac-container {
  top: 48px !important;
  left: 10px !important;
}
.dropdown-width-auto .ant-select-dropdown {
  max-width: 200px;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  display: none;
}
.ant-table-thead > tr > th::before {
  display: none;
}
.ant-input-clear-icon {
  line-height: 0;
}
//CUSTOM STYLE CLASSES END;
.alert-div {
  background-color: #fff1f0;
  display: flex;
  justify-content: center;
  padding: 5px 0px;
}
.wrong-icon {
  fill: #cf1322;
}
.disabled-button {
  svg {
    path {
      fill: @colorLabel;
    }
  }
}
.eqc-text {
  max-width: 200px;
}
.h1-typography {
  color: @headerTextColor;
  font-weight: 500;
  line-height: 1.2;
  font-size: 22px;
}
.h2-typography {
  color: @headerTextColor;
  font-weight: 500;
  line-height: 1.2;
  font-size: 20px;
}
.ant-dropdown {
  z-index: 999;
}
.ant-tooltip-inner {
  max-height: calc(100vh - 150px);
  overflow: auto;
}
.ant-tooltip {
  z-index: 1000 !important //due to inline style;;
}
.no-background-and-border {
  .ant-btn-round.ant-btn-icon-only {
    border: none;
    background: unset;
  }
}
.contact-action-button {
  button.ant-btn.ant-btn-round {
    margin-right: 10px;
  }
}

.image-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: @grey-10;
}

.data-count {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: @grey-10;
}

.gallery-info {
  .ant-popover-arrow {
    display: none;
  }
}
.gallery-image-popover {
  .ant-popover-content {
    max-width: 310px;
    height: auto;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      background: @light-blue;
      .ant-descriptions-item-label,
      .ant-descriptions-item-content {
        font-size: 16px;
      }
    }
  }
}
.global-agency-info {
  .ant-popover-arrow {
    display: none;
  }
  &.ant-popover {
    max-width: none;
    width: 300px;
  }
}
.no-tag-render {
  .ant-select-selection-overflow-item .ant-select-selection-item {
    display: none;
  }
  .ant-select-selector .ant-select-selection-item {
    display: none;
  }
}
.ant-select-item-option {
  font-size: 14px;
}
.readonly-select {
  border: 1px solid @border-color;
  border-radius: 40px;
  padding: 6px 8px;
  .anticon svg {
    fill: rgba(0, 0, 0, 0.25);
    width: 12px;
  }
}
.readonly-select-with-glow {
  border-color: @dq-colorPrimary;
  box-shadow: 0 0 0 2px rgba(252, 80, 39, 0.2);
}
.cursor-not-allowed {
  .readonly-select {
    background: #f5f5f5;
  }
}
.editable-table {
  .ant-table .ant-table-tbody .anticon {
    font-size: unset;
  }
  .contact-name {
    max-width: 200px;
  }
  .contact-name-with-count {
    max-width: 150px;
  }
  .eqc-type-contact-name {
    max-width: 150px;
  }
  .eqc-type-contact-name-with-count {
    max-width: 80px;
  }
  .contact-count {
    width: max-content;
  }
}
.ellipsis-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}
.placeholder-text {
  color: @grey-6;
}
.add-project-eqc-type-table {
  .ant-table-tbody > tr > td {
    max-width: 200px;
  }
}
.add-project-agency-table {
  .ant-table-tbody > tr > td {
    max-width: 300px;
  }
}
.add-approver-modal {
  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: @textColor;
  }
  .description {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    color: #b3b6c3;
  }
  .ant-modal-body {
    padding: 4px;
  }
  .heading {
    padding: 20px 20px 0;
  }
  .add-approval-form {
    max-height: calc(100vh - 400px);
    overflow: auto;
    overflow-x: hidden;
    padding: 0 16px 0 16px;
    .error-text {
      margin-top: -20px;
    }
    .approvalType-select {
      .ant-select-selector {
        border-radius: 8px;
      }
    }
  }
  .hide-overflow {
    overflow: hidden;
  }
}
.setup-module {
  &.ant-card-bordered {
    border: none;
  }
  @media screen and (max-width: 999px) {
    .mobile-card-wrapper {
      padding-top: 16px;
      .page-header {
        padding: 16px 16px 0;
      }
    }
    .setup-module > .ant-card-body {
      padding: 0;
    }
  }
  &.project-details .ant-tabs-tabpane {
    padding: 0;
  }
  .user-activity-count {
    .title {
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: #676c77;
      .count {
        color: @text-color;
      }
    }
  }
  .user-status-time {
    font-size: 10px;
    font-weight: 400;
    line-height: 16px;
    color: @text-color;
  }
  .user-info-popover {
    max-width: 360px;
    .ant-popover-inner {
      max-width: 316px;
      // width: max-content;
    }
    .ant-popover-inner-content {
      padding: 8px;
    }
    .ant-popover-arrow {
      display: none;
    }
    .user-info-div {
      max-width: 300px;
      // width: max-content;
      padding: 8px 12px 8px 10px;
      border-radius: 8px;
      background: #f2f4f7;
      border: 1px solid #e5e8ed;
      svg {
        circle {
          fill: @white-color;
        }
      }
    }
  }
  .down-arrow {
    transform: scale(1.3);
  }
  .user-count {
    svg {
      path {
        fill: @label-color;
      }
    }
  }
  .count-popover {
    width: auto;
    .ant-popover-inner-content {
      ul {
        padding: 0 0 0 20px;
        margin: 0;
      }
    }
  }
}
.name-and-time {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: @grey-10;
  .ellipsis-text {
    max-width: 200px;
  }
}
.checklist-changelog-popup.ant-popover {
  overflow: unset;
}
#view-approvers-drawer,
.checklist-changelog-popup,
.checklist-approvers-popup {
  max-width: 480px;
  width: fit-content;
  overflow: auto;
  overflow-x: hidden;
  .ant-descriptions-view table {
    table-layout: fixed;
  }
  .ant-descriptions-row > th,
  .ant-descriptions-row > td {
    padding: 0;
  }
  .outer {
    .ant-descriptions-item-label {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      margin-top: 14px;
    }
  }
  .inner {
    .ant-descriptions-item-label {
      color: #fff;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
      margin-top: 10px;
    }
    .ant-descriptions-item-content {
      color: #bfbfbf;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }
  .ant-popover-title {
    border: none;
    color: #cdd5e0;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 10px 0;
  }
  .ant-popover-arrow {
    .ant-popover-arrow-content {
      --antd-arrow-background-color: #101326;
    }
  }
  .ant-popover-inner {
    border-radius: 8px;
    background: #101326;
    box-shadow: 0px 25px 50px 0px rgba(81, 89, 131, 0.25);
    padding: 0;
  }
  .ant-popover-inner-content {
    padding: 0 10px 10px;
    color: @whiteColor;
    max-height: calc(100vh - 300px);
    overflow: auto;
  }
  .info-icon {
    transform: rotate(180deg);
  }
  ul {
    padding: 0 0 0 24px;
    margin-bottom: 0;
    li {
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}
#view-approvers-drawer {
  .ant-drawer-content-wrapper {
    width: 100% !important; //to get over some important styling
  }
  .ant-drawer-content {
    border-radius: 12px 12px 0px 0px;
    background: #101326;
    box-shadow: 0px 25px 50px 0px rgba(81, 89, 131, 0.25);
    color: @whiteColor;
    padding: 16px;
  }
  .title {
    color: #cdd5e0;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .ant-divider {
    border-top: 1px solid #676c77;
  }
  .ant-divider-horizontal {
    margin: 12px 0 0;
  }
  .ant-drawer-body {
    padding: 0;
  }
}
.note-margin {
  margin: -10px 0 10px 0;
}
.tags-div {
  max-width: 150px;
}
.react-flow__attribution a {
  display: none;
}
.react-flow__controls-button {
  height: 22px;
  width: 22px;
}

.tree-structure {
  width: 100%;
  height: calc(100vh - 335px);
  overflow: auto;
  display: grid;
  flex-direction: column;
  &.inside-modal {
    height: auto;
    max-width: 370px;
    width: 370px;
    max-height: calc(100vh - 296px);
    border: 1px solid @border-color;
  }
  .node-action {
    .add-button {
      transform: scale(0.8);
      path {
        fill: #000;
      }
    }
    display: none;
  }
  .ant-tree {
    flex: 1;
  }
  .ant-tree .ant-tree-treenode {
    border-bottom: 1px solid #e5e8ed;
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    padding: 5px;
    .site-tree-search-value {
      background-color: #00ff00;
    }
    &:hover {
      .node-action {
        display: flex;
        align-items: center;
      }
    }
  }
  .ant-tree .ant-tree-node-content-wrapper {
    width: 100%;
    &:hover {
      background-color: #ffdbc9;
    }
  }
  .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
  }
  .l0-tree-title {
    color: #fda793;
  }
  .l1-tree-title {
    color: #1890ff;
  }
  .l2-tree-title {
    color: #f5222d;
  }
  .l3-tree-title {
    color: #fa8c16;
  }
  .l4-tree-title {
    color: #722ed1;
  }
  .l5-tree-title {
    color: #52c41a;
  }
  .l6-tree-title {
    color: #13c2c2;
  }
  .l7-tree-title {
    color: #2f54eb;
  }
  .l8-tree-title {
    color: #eb2f96;
  }
  .l9-tree-title {
    color: #b3b6c3;
  }
  .disabled-tree-title {
    color: #b3b6c3;
    .level-name {
      text-decoration: line-through;
      text-decoration-color: @black-color;
    }
  }
}
.thin-scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
  &::-ms-scrollbar {
    width: 5px;
    height: 5px;
  }
  &::-ms-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
}
.sticky-action-buttons {
  display: flex;
  flex-direction: column;
  width: 90px;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  position: sticky;
  bottom: 0;
  border: 1px solid @border-color;
  background: #f5f5f5;
  box-shadow: 0px -2px 4px 0px rgba(62, 62, 62, 0.1);
  div {
    cursor: pointer;
    &.cursor-disabled {
      cursor: not-allowed;
      opacity: 0.5;
      .button-title {
        cursor: not-allowed;
      }
    }
    text-align: center;
    &:not(:last-child) {
      margin-bottom: 12px;
    }
    .button-title {
      font-size: 10px;
      font-weight: 400;
      line-height: 12px;
      text-align: left;
      color: @label-color;
    }
  }
  .enable-icon {
    path {
      fill: @text-color;
    }
  }
}
.pass-fail-count {
  max-width: 200px;
  .ant-popover-inner-content {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: @label-color;
    .count {
      color: @text-color;
    }
  }
}
#tabs-footer {
  .nomenclature-extra-info {
    padding: 5px;
    background-color: @border-color;
    width: fit-content;
    @media screen and (max-width: 999px) {
      margin: -16px 16px 16px;
    }
  }
}
.nomenclature-mobile-info {
  .ant-message-notice-content {
    padding: 8px 6px;
    svg {
      path {
        fill: #000000;
      }
    }
    background-color: #fff7e6;
    border: 1px solid #fa8c16;
  }
}
#upgrade-modal {
  .duplicate-icon {
    cursor: pointer;
    g {
      path {
        fill: @primary-color;
      }
    }
  }
  .ant-modal-mask {
    z-index: 1001;
  }
  .ant-modal-wrap {
    z-index: 9999;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: @label-color;
    margin-bottom: 10px;
  }
  .ant-modal-body {
    .form-buttons {
      padding: 0;
      .ant-btn {
        margin: 0;
        span {
          margin: 0;
        }
      }
    }
  }
  .ant-modal-close-x {
    svg {
      fill: @text-color;
    }
  }
  .ant-modal-close:hover {
    background-color: unset;
  }
}
.tags-col {
  .info-icon {
    path {
      fill: @label-color;
    }
  }
}
.organization-details {
  .info-icon {
    path {
      fill: @label-color;
    }
  }
}
.plan-info-content {
  h5 {
    margin: 0;
  }
}
.date-header-popover {
  h5 {
    margin: 0;
  }
}
.double-tick-icon {
  fill: #065f46;
}
.country-code-phone-input {
  .ant-input-group-addon:first-child {
    padding: 0;
  }
  .country-code-input {
    &.ant-form-item {
      margin-bottom: 0;
    }
    width: 80px;
    .ant-form-item-control-input {
      min-height: 30px;
    }
    .ant-select.ant-select-single {
      width: 100%;
    }
    .ant-select-selector {
      padding: 0;
    }
  }
}

.custom-date-picker-panel {
  .ant-picker-panel-layout {
    flex-direction: column-reverse;
    @media screen and (max-width: 999px) {
      max-width: 288px;
    }
    @media screen and (max-width: 999px) {
      .ant-picker-panels {
        .ant-picker-panel:nth-of-type(1) {
          button {
            visibility: visible !important; // Used important here to override antd inline CSS
          }
        }
        .ant-picker-panel:nth-of-type(2) {
          .ant-picker-header {
            button {
              visibility: hidden;
            }
          }
        }
      }
    }
    .ant-picker-presets {
      max-width: 100%;

      ul {
        height: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        border-right: none;
        border-top: var(--ant-line-width) var(--ant-line-type)
          var(--ant-color-split);
        gap: 16px;
        li {
          font-size: 12px;
          & + li {
            margin: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 999px) {
  .custom-date-picker-panel {
    .ant-picker-panel {
      &:last-child {
        width: 0;
        .ant-picker-header {
          position: absolute;
          right: 0;
          .ant-picker-header-prev-btn,
          .ant-picker-header-view {
            visibility: hidden;
          }
        }
        .ant-picker-body {
          display: none;
        }
      }
    }
  }
}

.timezone-div {
  font-size: 10px;
  color: #676c77;
  font-weight: 400;
  svg {
    height: 16px;
    width: 16px;
    min-width: unset;
    margin-right: 5px;
    g {
      fill: #676c77;
    }
  }
}
.mobile-layout {
  flex: 1 1;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
  align-self: auto;
}

[hidden] {
  display: none;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
  padding: 0;
}

.ant-pagination li:not(.ant-pagination-options) {
  border: 1px solid #d9d9d9;
}

.ant-pagination {
  li.ant-pagination-item-active {
    border-color: @dq-colorPrimary;
  }
}

::selection {
  background: #fc5027;
  color: white;
}

.ant-picker-dropdown.ant-picker-dropdown-placement-bottomRight
  .ant-picker-range-arrow {
  display: none;
}

.danger-button {
  color: #fff;
  border-color: #f5222d;
  background: #f5222d;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-table-placeholder {
  z-index: 999 !important; // due to inline styling need to use important
}
.ant-btn:not(:disabled):focus-visible {
  outline: none;
  outline-offset: unset;
}
@keyframes blink {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
.on-site-approval-bar {
  @media screen and (min-width: 999px) {
    margin: -15px -20px 10px -20px;
  }
  position: fixed;
  width: 100%;
  z-index: 9;
}
.blinking-banner {
  animation: blink 1s infinite;
}
.ant-pagination.ant-pagination-mini .ant-pagination-prev,
.ant-pagination.ant-pagination-mini .ant-pagination-next {
  margin: 0 8px;
}
.ant-btn.ant-btn-icon-only.ant-btn-round {
  width: 32px;
}
.ant-btn-default .ant-wave {
  display: none;
}
.ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
  border-color: @primary-color;
}
.width-max-content {
  width: max-content;
}
#router-prompt {
  .ant-modal {
    z-index: 9999;
  }
}
.ant-btn .ant-btn-icon {
  line-height: 0;
}
.ant-modal .ant-modal-close:hover {
  background-color: unset;
}
#sub-header {
  h5 {
    color: #676c77;
    font-weight: 400;
  }
}
.uploaded-images {
  max-height: 130px;
  display: flex;
  overflow: auto;
  flex-wrap: wrap;
  .ant-image .ant-image-img {
    padding: 8px;
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 6px;
  }
}
.upload-button {
  color: #676c77;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: 1px solid #d9d9d9;
}
.ant-upload {
  width: 100%;
}
.response-images {
  position: relative;
  height: 65px;
  width: 65px;
  display: flex;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin: 0 5px 5px 0;
  &:hover {
    .delete-response-image-button {
      opacity: 1;
      background: rgba(0, 0, 0, 0.5);
    }
  }
  .ant-image-mask {
    display: none;
  }
  .ant-image {
    width: 100%;
  }
}

.delete-response-image-button {
  width: calc(100% - 16px);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: calc(100% - 16px);
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  opacity: 0;
  transition: opacity 0.35s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  cursor: pointer;
  svg {
    fill: @white-color;
  }
}
.ant-form {
  input[type='file'] {
    display: none;
    color: transparent;
  }
  label.file-upload {
    font-size: 16px;
  }
}
.file-upload {
  border: 1px solid #d9d9d9;
  border-radius: 36px;
  padding: 7.9px 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #676c77;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  &.disabled {
    color: rgba(0, 0, 0, 0.25);
    border-color: #d9d9d9;
    background: #f5f5f5;
    text-shadow: none;
    box-shadow: none;
  }
}
.response-detail-card {
  border: 1px solid #e8e8e8;
}
.issue-heading {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  max-width: 50%;
}
